import { sum } from 'lodash-es'
import { storeToRefs } from 'pinia'
import type { CartResponseSuccess, ZnCartBundleMap } from '@/types/cart'
import { formatCurrency } from '@/utils/helper/formatCurrency'
import { useGuestCartStore } from '~/store/guestCart'
import { useState, computed, watch, useEmployeeAlgolia } from '#imports'
import type {
  ZnAlgoliaProduct,
  ZnAlgoliaProductLens,
  ZnAlgoliaProductTint,
} from '~/components/Zenni/Plp/algolia-types'
import type {
  ZnCartItemProps,
  ZnLensDetails,
} from '~/components/Zenni/ZnCartItem/types'
import type { ZnCartItem } from '~/types/cart'
import type { ZnOrderSummaryProps } from '~/components/Zenni/ZnOrderSummary/types'
import { useGuestStore } from '~/store/guest'
import {
  normalizationPhotochromicTintColor,
  normalizationTintColor,
} from '~/utils/normalize/tintNormalize'

export const NO_COATING = 'no_coating'
export const BLUE_LIGHT_CODE = 'blue-light'
export const LENS_TYPE_CLEAR = 'clear'
export const NO_BLUE_LIGHT_OPTION = 'n_a'
export const VISION_TYPE_PROGRESSIVE = 'progressive'
export const IS_EMPTY = '_empty'

export const frameImagesFields = [
  'Product_Eyeglass_Front_image_url',
  'Product_Eyeglass_Angle_image_URL',
  'Product_Eyeglass_Side_image_URL',
  'Product_Sunglass_Front_image_URL',
  'Product_Sunglass_Angle_image_URL',
  'Product_Sunglass_Side_image_URL',
  'Product_Clip_on_Front_image_URL',
  'Product_Clip_on_Angle_image_URL',
  'Product_Clip_on_Side_image_URL',
]

export const frameSunglassesImagesFields = [
  'Product_Sunglass_Front_image_URL',
  'Product_Sunglass_Angle_image_URL',
  'Product_Sunglass_Side_image_URL',
]

const getAllSkusFromBundles = (cart: CartResponseSuccess): string[] => {
  const bundleMaps = Object.values(cart?.bundle_map ?? {})
  if (bundleMaps.length === 0) return []
  const skus = new Set<string>()
  Object.values(bundleMaps).forEach((bundle) =>
    bundle.items.forEach((item) => skus.add(item.sku)),
  )
  return Array.from(skus)
}

type BundleItemMapDetails = {
  cart?: ZnCartItem
  product?: ZnAlgoliaProduct | ZnAlgoliaProductLens | ZnAlgoliaProductTint
}
type BundleItemMap = Record<string, BundleItemMapDetails>

const createBundleItemMap = (
  bundle: ZnCartBundleMap,
  cart: CartResponseSuccess,
  products: (ZnAlgoliaProduct | ZnAlgoliaProductLens)[],
): BundleItemMap => {
  const itemsMap: BundleItemMap = {}
  bundle.items.forEach((curr) => {
    const cartItem = cart.items.find((i) => i.uid === curr.uid)
    const product = products.find((p) => p.sku === cartItem?.product.sku)
    // we will use the family field as the key for the itemsMap
    const key = product?.family || 'default'
    if (!itemsMap[key]) {
      itemsMap[key] = {}
    }
    itemsMap[key] = { cart: cartItem, product }
  })
  return itemsMap
}

const formatLensSelections = (
  lens: BundleItemMapDetails,
  isPrescription: boolean,
  selections?: BundleItemMap,
) => {
  const lensSelections = []
  // add prescription type line
  const lensProduct = lens?.product as ZnAlgoliaProductLens
  if (isPrescription) {
    lensSelections.push({
      description: `${lensProduct?.LensPrescriptionType_data?.values?.label?.data} prescription`,
      price: 'Submitted',
    })
  } else {
    lensSelections.push({
      description: 'Non-prescription',
      price: 'Submitted',
    })
  }

  // has progressive?
  if (
    lensProduct?.Progressive_types &&
    lensProduct?.Progressive_types.length > 1 &&
    lensProduct?.Progressive_types[0] !== IS_EMPTY
  ) {
    lensSelections.push({
      description: `${lensProduct?.Progressive_types_data?.[
        lensProduct?.Progressive_types[0]
      ]?.values?.label?.data} progressive `,
      price: '',
    })
  }

  // lens type if is not progressive
  if (lensProduct?.Lens_Type !== VISION_TYPE_PROGRESSIVE) {
    lensSelections.push({
      description: `${lensProduct?.Lens_Type_data?.values?.label?.data} lens`,
      price: '',
    })
  }

  // has it blue light blocking?
  // if (lensProduct?.Blue_light_options !== NO_BLUE_LIGHT_OPTION) {
  //   lensSelections.push({
  //     description: 'Blue light lens',
  //     price: '',
  //   })
  // }

  // has sunglasses?
  if (lensProduct.Sunglasses_Type && lensProduct.Sunglasses_Type !== IS_EMPTY) {
    lensSelections.push({
      description: `${lensProduct?.Sunglasses_Type_data?.values?.label?.data} sunglasses `,
      price: '',
    })
  }

  // has it photochromic?
  if (
    lensProduct.Photochromic_type &&
    lensProduct.Photochromic_type !== IS_EMPTY
  ) {
    lensSelections.push({
      description: `${
        lensProduct?.Photochromic_type_data?.values?.title?.data ||
        lensProduct?.Photochromic_type_data?.values?.label?.data
      }`,
      price: '',
    })
  }

  // if there is a tint, we should include it in the lens index price, so we should see if there is a tint
  const tint = selections?.tint?.product as ZnAlgoliaProductTint

  // add lens index line
  const indexName = lensProduct?.Lens_Index_data?.values?.name?.data
  lensSelections.push({
    description: indexName
      ? indexName.includes('lens')
        ? indexName
        : `${indexName} lens index`
      : lensProduct?.Name,
    price:
      (lens?.cart?.product?.price_range?.maximum_price?.final_price?.value ||
        0) + (tint?.Price || 0) || 'Included',
  })

  // add any other selections
  Object.values(selections || {}).forEach((selection) => {
    if (selection?.product?.family.toLowerCase().includes('prism')) {
      lensSelections.push({
        description: selection.product.Name,
        price:
          selection?.cart?.product?.price_range?.maximum_price?.final_price
            ?.value || 'Included',
      })
    }
    if (selection?.product?.family.toLowerCase().includes('coating')) {
      const coating = selection.product as ZnAlgoliaProductTint
      if (
        coating?.coating_resistant_type_data?.values?.hideprice?.data?.code ===
        'yes'
      ) {
        // if the coating has hideprice field, it must not be included in the lens selections
      } else if (coating?.Tint___type_data) {
        // if the coating has tint type field, it must be treated as tint instead of coating
        lensSelections.push({
          description: `${coating?.Tint___Color_data?.values?.label?.data}`,
          price:
            selection?.cart?.product?.price_range?.maximum_price?.final_price
              ?.value || 'Included',
        })
      } else {
        const coatingName = coating?.Name || ''
        lensSelections.push({
          description: coatingName.toLowerCase().includes('coating')
            ? coatingName
            : `${coatingName} coating`,
          price:
            selection?.cart?.product?.price_range?.maximum_price?.final_price
              ?.value || 'Included',
        })
      }
    }
  })

  if (tint && !('Tint___type' in (selections?.coating?.product || {}))) {
    lensSelections.push({
      description: tint?.Tint___Color_data?.values?.label?.data,
      price: tint?.Price || 'Included',
    })
  }

  return lensSelections
}

export const getFirstImage = (
  product: ZnAlgoliaProduct,
  isSunglasses = false,
) => {
  const imgFields =
    isSunglasses && product?.categories?.includes('usv_sunglasses')
      ? (frameSunglassesImagesFields as (keyof ZnAlgoliaProduct)[])
      : (frameImagesFields as (keyof ZnAlgoliaProduct)[])
  const imgField = imgFields.find((field) => product[field])
  return imgField ? product[imgField] : undefined
}

const mapCartItems = (
  cart: CartResponseSuccess,
  products: ZnAlgoliaProduct[],
): ZnCartItemProps[] => {
  const cartItems: ZnCartItemProps[] = []
  const bundles = Object.keys(cart?.bundle_map || {})
  bundles.forEach((bundleId) => {
    const bundle = cart.bundle_map[bundleId]

    const { frame, lens, ...selections } = createBundleItemMap(
      bundle,
      cart,
      products,
    )

    // we need to add the lens price and all selections if there are any
    const lensPrice =
      (lens?.cart?.product?.price_range?.maximum_price?.final_price?.value ||
        0) +
      Object.values(selections || {}).reduce(
        (acc, curr) =>
          acc +
          (curr?.cart?.product?.price_range?.maximum_price?.final_price
            ?.value || 0),
        0,
      )

    const lensDetails: ZnLensDetails = {
      price: lensPrice,
      prescription: bundle.prescription,
      selections: formatLensSelections(lens, bundle.isPrescription, selections),
      isPrescription: bundle.isPrescription,
    }

    const lensHasSunglasses =
      (lens?.product as ZnAlgoliaProductLens)?.Sunglasses_Type !== IS_EMPTY

    const frameProduct = frame?.product as ZnAlgoliaProduct
    const price = frameProduct?.Price ?? 0
    const tint = selections?.tint?.product as ZnAlgoliaProductTint
    cartItems.push({
      id: bundleId,
      image: (getFirstImage(frameProduct, lensHasSunglasses) as string) || '',
      title: frameProduct?.Name || '',
      color: frameProduct?.Main_Color_data?.values?.label?.data || '',
      size: frameProduct?.Frame___Size_data?.values?.label?.data || '',
      tintClass: normalizationPhotochromicTintColor(lens?.product),
      tint: tint ? normalizationTintColor(tint.Tint___Color) : undefined,
      quantity: 1,
      price: price ?? 0,
      subtotal: (price ?? 0) + lensPrice,
      lensDetails,
      hideButtons: false,
    })
  })

  return cartItems
}

type UseCartConfig = {
  taxCalculated: boolean
}

export const useCart = (
  { taxCalculated }: UseCartConfig = { taxCalculated: false },
) => {
  const { cart } = storeToRefs(useGuestCartStore())
  const guestStore = useGuestStore()
  const { currentGift } = storeToRefs(guestStore)
  const hits = useState<ZnAlgoliaProduct[]>(() => [])

  const getProductsFromAlgolia = async (cart: CartResponseSuccess) => {
    const skus = getAllSkusFromBundles(cart)
    if (skus.length === 0) return
    const { search, result: data } = useEmployeeAlgolia<ZnAlgoliaProduct>()
    await search(
      {
        query: '',
        requestOptions: {
          filters: `${skus.map((sku) => `sku:"${sku}"`).join(' OR ')}`,
        },
      },
      false,
    )
    hits.value = data.value?.hits || []
  }
  if (cart.value) getProductsFromAlgolia(cart.value)
  watch(
    () => cart.value,
    (newCart) => {
      if (newCart) {
        hits.value = []
        getProductsFromAlgolia(newCart)
      }
    },
    { deep: true, immediate: true },
  )

  const cartItems = computed(() =>
    cart.value?.items && hits.value.length > 0
      ? mapCartItems(cart.value, hits.value)
      : [],
  )

  const cartItemsCount = computed(
    () => Object.keys(cart.value?.bundle_map || {}).length,
  )

  const remainingCredit = computed(() =>
    currentGift.value ? guestStore.calculateGiftAmt(currentGift.value) : 0,
  )

  const cartTotals = computed<ZnOrderSummaryProps>(() => {
    const shippingPrice =
      ((cart.value?.shipping_addresses?.[0]?.selected_shipping_method as any)
        ?.amount?.value || 0) -
      ((cart.value?.shipping_addresses?.[0]?.selected_shipping_method as any)
        ?.discount_amount || 0)
    const salesTax =
      ((cart.value?.prices?.subtotal_including_tax as any)?.value || 0) -
      ((cart.value?.prices?.subtotal_excluding_tax as any)?.value || 0) +
      ((cart.value?.shipping_addresses?.[0]?.selected_shipping_method as any)
        ?.tax_amount || 0)
    // const framePriceSum = sum(cartItems.value.map((item) => item.price))
    // const frameDiscountSum = sum(
    //   cartItems.value.map(
    //     (item) => (item.promoPrice ?? item.price) - item.price,
    //   ),
    // );
    // const frameDiscountSum =
    //   cart?.prices?.discounts?.find((row) => row.label === 'welcome10')?.amount
    //     ?.value || 0
    const subtotalSum = sum(cartItems.value.map((item) => item.subtotal))

    const totalSum = cart.value?.prices.grand_total.value || 0
    const creditsUsed = Math.min(remainingCredit.value, totalSum as number)
    const total = (totalSum as number) - creditsUsed

    const shippingMethodName = (
      cart.value?.shipping_addresses?.[0]?.selected_shipping_method as any
    )?.method_title

    const shippingValue =
      shippingPrice > 0
        ? formatCurrency(shippingPrice, 'en-US', {
            currency: 'USD',
            minimumFractionDigits: 2,
          })
        : !taxCalculated
        ? 'Calculated at checkout'
        : 'Free'

    return {
      total: Math.round(total * 100) * 0.01,
      discount: 0, // -frameDiscountSum,
      creditsUsed,
      lines: {
        ...(shippingMethodName
          ? { [`Shipping - ${shippingMethodName}`]: shippingValue }
          : { Shipping: shippingValue }),
        Tax:
          salesTax > 0
            ? formatCurrency(salesTax, 'en-US', {
                currency: 'USD',
                minimumFractionDigits: 2,
              })
            : taxCalculated
            ? 'Free'
            : 'Calculated at checkout',
      },
      itemCount: cartItems.value?.length || 0,
      subtotal: subtotalSum,
      promoCode: cart.value?.applied_coupons?.[0]?.code,
      remainingCredits: remainingCredit.value - creditsUsed,
    }
  })

  const availableCredits = computed<number>(
    () => remainingCredit.value - (cartTotals.value?.creditsUsed ?? 0),
  )

  return {
    cartItems,
    cartTotals,
    cartItemsCount,
    availableCredits,
  }
}
